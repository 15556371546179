import { EdgeModel, DataModel, PagesOptionModel, PageModel } from './models';

// Map the raw GraphQL data to our page's data model
export function mapData(
  pageData: DataModel,
  pagesOptionModel: PagesOptionModel
): PageModel[] {
  const pages = pageData.allSitePage.edges.reduce(
    (acc: PageModel[], { node }: EdgeModel) => {
      // don't index pages w/o page Ids
      if (!node.context || node.context.pageId === null) {
        return acc;
      }
      const { pageId, locale } = node.context;
      // try to find the page(s)
      let pages: PageModel[] = acc.filter((p) => p.id === pageId);
      // if not found, create and add it(them)
      if (pages.length === 0) {
        pages = pagesOptionModel[pageId].map((pom) => ({
          id: pageId,
          description: pom.description ?? '[missing description]',
          urls: {},
        }));
        acc.push(...pages);
      }
      pages.forEach((page) => {
        const qs =
          pagesOptionModel[pageId].find(
            (pom) => pom.description === page.description
          )?.querystring ?? '';
        // add the url w/ optional querystring
        page.urls[locale] = `${node.path}${qs}`;
      });

      return acc;
    },
    [] // start w/ empty array
  );
  return pages;
}
