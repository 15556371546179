import React from 'react';
import { TableRow, TableCell, Button, Icon, Link } from '@vp/swan';
import { LocaleModel, countries, languages } from '@vp/digital-locale-lib';
import { PageModel } from './models';
import { usePathSuffix } from '@vp/digital-environment-lib';

const NO_PAGE_TEXT = '--';

interface LocaleRowProps {
  locale: LocaleModel;
  pages: Array<PageModel>;
  setPathSuffix: boolean;
}

const LocaleRow = ({ pages, locale, setPathSuffix }: LocaleRowProps) => {
  const country = countries[locale.countryCode];
  const language = languages[locale.languageCode];
  const pathSuffix = usePathSuffix();

  return (
    <TableRow>
      <TableCell key="locale">{locale.identifier}</TableCell>
      <TableCell key="country">{country.description}</TableCell>
      <TableCell key="language">{language}</TableCell>
      <TableCell key="rank">{country.rank}</TableCell>
      {pages.map((page) => {
        const link = page.urls[locale.identifier] ? (
          <Link
            href={`${page.urls[locale.identifier]}${
              setPathSuffix ? pathSuffix : ''
            }`}
          >
            <Button buttonShape="round">
              <Icon iconType="chevronRight" />
            </Button>
          </Link>
        ) : (
          NO_PAGE_TEXT
        );
        return <TableCell key={page.description}>{link}</TableCell>;
      })}
    </TableRow>
  );
};

export default LocaleRow;
