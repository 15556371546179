/* eslint-disable prettier/prettier */
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import {
  TableScrollContainer,
  Table,
  TableBody,
  BoundedContent,
} from '@vp/swan';
import {
  HtmlAttributes,
  RootLayout,
  SwanStyleLoader,
} from '@vp/digital-site-layout-lib';
import {
  LocaleModel,
  countries,
  languages,
  makeAllLocales,
  LocaleProvider,
} from '@vp/digital-locale-lib';
import { RuntimeContextProvider } from '@vp/digital-environment-lib';
import { DataModel, ContextModel, OnSort, PageModel } from './models';
import Header from './Header';
import Hero from './Hero';
import LocaleRow from './LocaleRow';
import { makeComparatorPair, ComparatorPair } from './Comparator';
import { mapData } from './mapper';

import { swanStyleKeys } from './swanKeys';
import './PageIndex.scss';

// map column names to comparators for the column
const comparators: Record<string, ComparatorPair<LocaleModel>> = {
  Locale: makeComparatorPair<LocaleModel>('identifier', (p) => p),
  Country: makeComparatorPair<LocaleModel>(
    'countryCode',
    (p) => countries[p].description
  ),
  Rank: makeComparatorPair<LocaleModel>(
    'countryCode',
    (p) => countries[p].rank
  ),
  Language: makeComparatorPair<LocaleModel>(
    'languageCode',
    (p) => languages[p]
  ),
};

interface PageIndexProps {
  data: DataModel;
  pageContext: ContextModel;
}

const PageIndex = ({
  data,
  pageContext: {
    mfeName,
    buildTime,
    pages: pagesOptionModel,
    getEnvironment,
    setPathSuffix,
  },
}: PageIndexProps) => {
  const [allLocales, setAllLocales] = useState(() => makeAllLocales());
  const [sortData, setSortData] = useState({ column: 'Locale', asc: true });

  const onSort: OnSort = (column: string): void => {
    const newAsc = sortData.column === column ? !sortData.asc : true;
    const newLocales = [].concat(allLocales).sort(comparators[column](newAsc));
    setSortData({ column, asc: newAsc });
    setAllLocales(newLocales);
  };
  const pages: Array<PageModel> = mapData(data, pagesOptionModel);
  return (
    <LocaleProvider allowDefault={true}>
      <RuntimeContextProvider getEnvironment={getEnvironment}>
        <SwanStyleLoader />
        <RootLayout swanStyleKeys={swanStyleKeys}>
          <HtmlAttributes />
          <BoundedContent>
            <Hero mfeName={mfeName} buildTime={buildTime} />
            <TableScrollContainer>
              <Table skin="simple">
                <Header pages={pages} sortData={sortData} onSort={onSort} />
                <TableBody>
                  {allLocales.map((locale) => (
                    <LocaleRow
                      pages={pages}
                      locale={locale}
                      key={locale.description}
                      setPathSuffix={setPathSuffix}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableScrollContainer>
          </BoundedContent>
        </RootLayout>
      </RuntimeContextProvider>
    </LocaleProvider>
  );
};

export default PageIndex;

export const query = graphql`
  query {
    allSitePage {
      edges {
        node {
          path
          context {
            locale
            pageId
          }
        }
      }
    }
  }
`;
