import React from 'react';
import { HeroBackground } from '@vp/digital-hero-lib';
import {
  StandardHero,
  StandardHeroTextContainer,
  StandardHeroText,
  StandardHeroTextInner,
  StandardHeroHeading,
  StandardHeroDescription,
} from '@vp/swan';

interface HeroProps {
  mfeName: string;
  buildTime: string;
}

const Hero = ({ mfeName, buildTime }: HeroProps) => (
  <StandardHero
    textHorizontalAlign="center"
    textVerticalAlign="center"
    textBackground="standard"
    skin="standard"
    fullWidthImage
    height="short"
  >
    <StandardHeroTextContainer>
      <StandardHeroText>
        <StandardHeroTextInner bgc="white">
          <StandardHeroHeading>{mfeName} Page Index</StandardHeroHeading>
          <StandardHeroDescription>
            Click on on any button below to visit a specific page. Build time{' '}
            {buildTime}
          </StandardHeroDescription>
        </StandardHeroTextInner>
      </StandardHeroText>
    </StandardHeroTextContainer>
    <HeroBackground backgroundClassName="index-hero__background" />
  </StandardHero>
);

export default Hero;
