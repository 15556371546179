// type for a function that compares two objects of type T
export type Comparator<T> = (a: T, b: T) => number;

// type for a functions that returns either an ascending or descending comparator for objects of type T
export type ComparatorPair<T> = (asc: boolean) => Comparator<T>;

// function that makes ComparatorPair<LocaleModel>
export const makeComparatorPair =
  <T>(prop: keyof T, lookup: (p: any) => string | number): ComparatorPair<T> =>
  (asc: boolean): Comparator<T> => {
    const ASC_LOW_IND = -1;
    const ASC_HIGH_IND = 1;
    const DESC_LOW_IND = 1;
    const DESC_HIGH_IND = -1;
    const [lowInd, highInd] = asc
      ? [ASC_LOW_IND, ASC_HIGH_IND]
      : [DESC_LOW_IND, DESC_HIGH_IND];
    return (a: T, b: T) =>
      lookup(a[prop]) < lookup(b[prop]) ? lowInd : highInd;
  };
