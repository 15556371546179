import React from 'react';
import { TableHead, TableRow, TableCell } from '@vp/swan';
import { PageModel, SortData, OnSort } from './models';
import HeaderCell from './HeaderCell';

interface HeaderProps {
  pages: Array<PageModel>;
  sortData: SortData;
  onSort: OnSort;
}

const Header = ({ pages, sortData, onSort }: HeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        <HeaderCell column="Locale" sortData={sortData} onSort={onSort} />
        <HeaderCell column="Country" sortData={sortData} onSort={onSort} />
        <HeaderCell column="Language" sortData={sortData} onSort={onSort} />
        <HeaderCell column="Rank" sortData={sortData} onSort={onSort} />
        {pages.map((page) => (
          <TableCell key={page.id}>{page.description}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default Header;
