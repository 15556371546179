import React from 'react';
import { Icon, TableCell, Button } from '@vp/swan';
import { SortData, OnSort } from './models';

interface HeaderCellProps {
  column: string;
  sortData: SortData;
  onSort: OnSort;
}

const HeaderCell = ({ column, sortData, onSort }: HeaderCellProps) => {
  const iconType =
    column !== sortData.column
      ? 'minus'
      : sortData.asc
      ? 'caretUp'
      : 'caretDown';
  return (
    <TableCell key={column} onClick={() => onSort(column)}>
      <Button
        skin="secondary"
        size="mini"
        width="standard"
        iconPosition="right"
      >
        {column}
        <Icon skin="standard" iconType={iconType} ml={4} />
      </Button>
    </TableCell>
  );
};

export default HeaderCell;
